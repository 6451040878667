import React from 'react';
export function parseBoolean(value) {
    return [true, 'true', 'True', 'TRUE', '1', 1].includes(value);
}
export function capitalizeFirstLetter(value) {
    return value.charAt(0).toUpperCase() + value.slice(1);
}
export function capitalizeEachFirstLetter(value) {
    if (!value) {
        return ' ';
    }
    const words = value.split(' ');
    const result = words.map((word) => {
        return word[0].toUpperCase() + word.substring(1);
    });
    return result.join(' ');
}
export function parseTime(num) {
    const hours = Math.floor(num / 60);
    const minutes = num % 60;
    const time = `${hours}h ${minutes}m`;
    return time;
}
/**
 * Formats a string by making a specific sentence bold at the specified index.
 * @param {string} string - The input string to format.
 * @param {number} boldSentenceIndex - The index of the sentence to make bold (zero-indexed).
 * @returns {JSX.Element} - The formatted string as a JSX element.
 */
export function boldSentenceAtIndex(string, boldSentenceIndex) {
    const sentences = string.split('. ');
    return (React.createElement(React.Fragment, null, sentences.map((sentence, index) => {
        if (sentence === '') {
            return sentence;
        }
        if (index < sentences.length - 1) {
            return index === boldSentenceIndex ? React.createElement("strong", null,
                sentence,
                ". ") : `${sentence}. `;
        }
        return index === boldSentenceIndex ? React.createElement("strong", null,
            sentence,
            ".") : `${sentence}.`;
    })));
}
export function removeExcessBrTags(input) {
    // Replace three or more consecutive <br> tags (allowing up to two)
    return input.replace(/(<br\s*\/?>[\s\u00A0]*){3,}/gi, '<br><br>');
}
export function removeLineSeparators(input) {
    return input.replace(/\u2028/g, '');
}
